/* eslint-disable @next/next/no-img-element */
//import Robot from '@iso/assets/images/rob.png';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button } from 'antd';
import Head from 'next/head';
import Link from 'next/link';
import FourZeroFourStyleWrapper from '../styled/404.styles';

// import Image from '@iso/assets/images/rob.png';
function FourZeroFour() {
  return (
    <FourZeroFourStyleWrapper className="iso404Page">
      <div className="iso404Content">
        <h1>
          <IntlMessages id="page404.title" />
        </h1>
        <h3>
          <IntlMessages id="page404.subTitle" />
        </h3>
        <p>
          <IntlMessages id="page404.description" />
        </p>
        {/* <button id="home" type="button"> */}
        <Link href="/dashboard" className="isoMenuHolder">
          <Button id="home">
            <span className="nav-text">
              <IntlMessages id="page404.backButton" />
            </span>
          </Button>
        </Link>
        {/* </button> */}
      </div>

      <div className="iso404Artwork">
        <img alt="#" src="/images/rob.png" />
      </div>
    </FourZeroFourStyleWrapper>
  );
}

export default function FourZeroFourContainer() {
  return (
    <>
      <Head>
        <title>404</title>
      </Head>
      <FourZeroFour />
    </>
  );
}
